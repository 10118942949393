import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Header from "./components/Header";

import Catalogue from "./components/Catalogue";
import Events from "./components/Events";
import News from "./components/News";
import Agency from "./components/Agency";
import Contact from "./components/Contact";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route element={<Catalogue />} path="/" />
        <Route element={<Events />} path="/events" />
        <Route element={<Agency />} path="/agency" />
        <Route element={<News />} path="/news" />
        <Route element={<Contact />} path="/contact" />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
