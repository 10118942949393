import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";

import "./Catalogue.scss";

export default function Catalogue() {
  const [allItemsData, setAllItems] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "catalogue"] | order(order asc){
        slug,
        releaseCatNo,
        releaseTitle,
        releaseArtist,
        linkToBuy,
        linkToListen,
        releaseArtwork{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllItems(data))
      .catch(console.error);
  }, []);

  const isMobile = window.innerWidth < 750;

  // let isMobile = false;
  // useEffect(()=> {
  //   window.addEventListener('resize', ()=>{
  //     if(window.innerWidth < 750){ isMobile = true }else{ isMobile = false};
  //     console.log(isMobile);
  //   });
  // })
  return (
    <section className="section__wrapper catalogue__wrapper" id="catalogue">
      {allItemsData &&
        allItemsData.map((item, index) => (
          <div className="catalogue__item-wrapper" key={item.releaseTitle}>
            {isMobile && (
              <div className="catalogue__title">
                {item.releaseCatNo} :: {item.releaseArtist} – '
                {item.releaseTitle}'
              </div>
            )}
            <div
              className="catalogue__image-wrapper"
              style={{
                backgroundImage: `url(${item.releaseArtwork.asset.url})`,
              }}
            >
              {!isMobile && (
                <div className="catalogue__buy-listen">
                  <a href={item.linkToBuy } target="_blank" rel="noreferrer">
                    <h2>Buy</h2>
                  </a>
                  <a href={item.linkToListen } target="_blank" rel="noreferrer">
                    <h2>Listen</h2>
                  </a>
                </div>
              )}
            </div>
            {!isMobile && (
              <div className="catalogue__title">
                {item.releaseCatNo} :: {item.releaseArtist} – '
                {item.releaseTitle}'
              </div>
            )}
            {isMobile && (
              <div className="catalogue__buy-listen">
                  <a href={item.linkToBuy } target="_blank" rel="noreferrer">
                    <h2>Buy</h2>
                  </a>
                  <a href={item.linkToListen } target="_blank" rel="noreferrer">
                    <h2>Listen</h2>
                  </a>
              </div>
            )}
          </div>
        ))}
    </section>
  );
}
