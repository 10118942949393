import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../src/assets/transatlantic.jpg"

import "./Header.scss";

export default function Header() {
  return (
    <header className="header__wrapper">
      <div className="header__item logo">
        <img src={logo} alt="TRANSATLANTIC" />
      </div>
      <div className="header__item">
        <NavLink activeclassname="active" to="/">Catalogue</NavLink>
      </div>
      <div className="header__item">
        <NavLink activeclassname="active" to="/events">Events</NavLink>
      </div>
      <div className="header__item">
        <NavLink activeclassname="active" to="/agency">Agency</NavLink>
      </div>
      <div className="header__item">
        <NavLink activeclassname="active" to={"/news"}>News</NavLink>
      </div>
      <div className="header__item">
        <NavLink activeclassname="active" to="/contact">Contact</NavLink>
      </div>
    </header>
  );
}
