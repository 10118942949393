import React, { useState, useEffect, useRef } from "react";
import sanityClient from "../client.js";

import "./Agency.scss";

export default function Agency() {
  const [allItemsData, setAllItems] = useState(null);
  const imageWrapper = useRef();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "agency"] | order(artistName asc){
          _id,
        slug,
        artistName,
        artistSoundcloud,
        artistImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllItems(data))
      .catch(console.error);
  }, []);

  const onMouseEnter = (e) => {
    imageWrapper.current.style.backgroundImage = `url('${e.target.dataset.image}')`;
    imageWrapper.current.style.opacity = 1;
  }

  const onMouseLeave = (e) => {
    imageWrapper.current.style.backgroundImage = null;
    imageWrapper.current.style.opacity = 0;
  }

  const handleMouseMove = (e) => {
    let pos = {
      x: e.clientX,
      y: e.clientY
    }
    imageWrapper.current.style.left = `${pos.x + 20}px`;
    imageWrapper.current.style.top = `${pos.y + 20}px`;
  }
  useEffect(()=>{
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    }
  }, [])

  return (
    <section className="section__wrapper" id="agency">
      <div className="agency__image-wrapper" ref={imageWrapper}></div>
      <div className="agency__list-wrapper">
        {allItemsData &&
          allItemsData.map((item, index) => (
            <a
              href={item.artistSoundcloud}
              target="_blank"
              rel="noreferrer"
              className="agency__item-wrapper"
              key={item._id}
              data-image={item.artistImage.asset.url}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              {item.artistName}
            </a>
          ))}
      </div>
    </section>
  );
}
