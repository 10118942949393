import React, {useEffect, useState} from "react";
import sanityClient from "../client.js";
import "./Contact.scss";

export default function Contact() {
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "contact"]{
          contactDetails
        }`
      )
      .then((data) => setPostData(data))
      .catch(console.error);
  }, []);

  return (
    <section className="contact__wrapper" id="contact">
      {postData && postData[0].contactDetails.map((item, index) => {
        return (
          <a
            href={item.entryLink}
            target={"_blank"}
            rel={"noreferrer"}
            className="contact__item"
            key={index}
          >
            <h1 className="footer__credit-title">{item.entryName}</h1>
          </a>
        );
      })}
    </section>
  );
}
