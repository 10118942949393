import React, { useState, useEffect} from "react";
import sanityClient from "../client.js";

import "./Events.scss";

export default function Events() {
  const [allItemsData, setAllItems] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "events"] | order(order asc){
          _id,
        slug,
        eventDate,
        eventLoacation,
        eventArtists,
        eventTickets,
        eventPoster{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllItems(data))
      .catch(console.error);
  }, []);
  return (
    <section className="section__wrapper" id="events">
      <div className="events__list-wrapper">
        {allItemsData &&
          allItemsData.map((item, index) => (
            <div className="events__item-wrapper" key={item._id}>
             <div className="events__image-wrapper">
               <img src={item.eventPoster.asset.url} alt={item.eventLoacation} />
             </div>
              <span>—</span>
              <span>{item.eventDate}</span>
              <span>
                {item.eventLoacation} <br /> w/ {item.eventArtists}
              </span>
              <span>
                <a href={item.eventTickets} target="_blank" rel="noreferrer">
                  Tickets
                </a>
              </span>
            </div>
          ))}
      </div>
    </section>
  );
}
