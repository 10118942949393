import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import { PortableText } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";

import "./News.scss";

export default function News() {
  const [postData, setPostData] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "news"]{
            date,
            title,
            description,
            poster
            }`
      )
      .then((data) => setPostData(data))
      .catch(console.error);
  }, []);

  const PortableTextComponents = {
    marks: {
      link: ({ value, children }) => {
        const target = (value?.href || "").startsWith("http")
          ? "_blank"
          : undefined;
        return (
          <a
            href={value?.href}
            target={target}
            rel={target === "_blank" && "noindex nofollow"}
          >
            {children}
          </a>
        );
      },
    },
  };

  const builder = imageUrlBuilder(sanityClient);
  function urlFor(source) {
    return builder.image(source);
  }

  return (
    <section className="section__wrapper" id="news">
      <div className="news__container">
        {postData &&
          postData.map((item, index) => (
            <div className="news__item-wrapper" key={index}>
              <h2>{item.date}</h2>
              <h2>{item.title}</h2>
              <PortableText
                value={item.description}
                components={PortableTextComponents}
              />
              {item.poster ? (
                <img src={urlFor(item.poster).url()} alt={item.title} className="news__image" />
              ) : null}
            </div>
          ))}
      </div>
    </section>
  );
}
